// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, toJS, observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcIsUndefined,
  AcFormatRequestParameters,
  AcFormatRawDataAsList,
} from '@utils';

const _default = {
  options: {},
  equipment: null,
  counts: null,
};

let app = {};

export class EquipmentStore {
  constructor(store) {
    makeObservable(this);

    // AcAutoLoad(this, KEYS.POWERPACK_TYPES);
    // AcAutoLoad(this, KEYS.POWERPACK_TYPE);
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  equipment = null;

  @observable
  filters = null;

  @observable
  counts = null;

  @computed
  get current_counts() {
    return this.counts && toJS(this.counts);
  }

  @computed
  get current_equipment() {
    return this.equipment && toJS(this.equipment);
  }

  @computed
  get current_filters() {
    return this.filters && toJS(this.filters);
  }

  @computed
  get current_equipment_list() {
    if (!this.current_equipment) return [];

    let result = new AcFormatRawDataAsList(this.current_equipment, {
      ID: 'id',
      EQUIPMENT_GROUP: null,
      EQUIPMENT_TYPE: 'equipment_type',
      OBJECT_NO: 'object_no',
      STATUS: 'status',
    });

    result = result.map((item) => ({
      ...item,
      equipment_type: item.equipment_type.name,
      equipment_group: item.equipment_type.group,
      equipment_meta: item.equipment_type,
      series: item.equipment_type.series,
    }));

    return result;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: KEYS.ASCENDING,
  };

  @observable
  page = 1;

  @observable
  query = null;

  @action
  setQuery = (input) => {
    const _input = AcSanitize(input);
    if (this.query !== _input) this.query = _input;
  };

  @action
  resetParams = () => {
    this.page = 1;
    this.query = null;
    this.per_page = 0;
    this.order_by = { field: null, direction: KEYS.ASCENDING };
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @action
  list = (options) => {
    this.setLoading(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.equipment
      .list(params)
      .then((response) => {
        const { data, counts, filters } = response;

        console.log('filters', filters);

        this.set(KEYS.EQUIPMENT, data, false);
        this.set(KEYS.COUNTS, counts, false);
        this.set(KEYS.FILTERS, filters, false);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve equipment',
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default EquipmentStore;
