// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, observable, computed, action } from 'mobx';

// Imports => Constants
import { DATETIME_FORMATS, KEYS } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcIsUndefined,
  AcFormatRequestParameters,
  AcFormatRawDataAsList,
  AcFormatDate,
} from '@utils';

const _default = {
  options: {},
  control_unit_types: null,
  control_unit_type: null,
};

let app = {};

export class ControlUnitTypesStore {
  constructor(store) {
    makeObservable(this);

    // AcAutoLoad(this, KEYS.CONTROL_UNIT_TYPES);
    // AcAutoLoad(this, KEYS.CONTROL_UNIT_TYPE);
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  control_unit_types = null;

  @observable
  control_unit_type = null;

  @observable
  control_unit_type_software = null;

  @computed
  get current_control_unit_types() {
    return this.control_unit_types;
  }

  @computed
  get current_control_unit_types_list() {
    if (
      !this.current_control_unit_types ||
      !this.current_control_unit_types.data
    )
      return [];

    const result = new AcFormatRawDataAsList(
      this.current_control_unit_types.data,
      {
        ID: 'id',
        NAME: 'name',
        SERIES: 'series',
      }
    );

    return result;
  }

  @computed
  get current_control_unit_type() {
    return this.control_unit_type;
  }

  @computed
  get current_control_unit_type_software() {
    return this.control_unit_type_software;
  }

  @computed
  get current_control_unit_type_software_list() {
    if (!this.current_control_unit_type_software) return [];

    const collection = this.current_control_unit_type_software.map((item) => ({
      ...item,
      released_at: AcFormatDate(
        item.released_at,
        DATETIME_FORMATS.RAW_DATE,
        DATETIME_FORMATS.DATE
      ),
      available_at:
        (item.available_at &&
          AcFormatDate(
            item.available_at,
            DATETIME_FORMATS.RAW_DATE,
            DATETIME_FORMATS.DATE
          )) ||
        '-',
    }));

    const result = new AcFormatRawDataAsList(collection, {
      ID: 'id',
      NAME: 'name',
      RELEASED_AT: 'released_at',
      AVAILABLE_SINCE: 'available_at',
    });

    return result;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: KEYS.ASCENDING,
  };

  @observable
  page = 1;

  @observable
  query = null;

  @action
  setQuery = (input) => {
    const _input = AcSanitize(input);
    if (this.query !== _input) this.query = _input;
  };

  @action
  resetParams = () => {
    this.page = 1;
    this.query = null;
    this.per_page = 0;
    this.order_by = { field: null, direction: KEYS.ASCENDING };
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @action
  list = (options, endpoint = KEYS.LIST) => {
    this.setLoading(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.control_unit_types[endpoint](params)
      .then((response) => {
        this.set(KEYS.CONTROL_UNIT_TYPES, response, false);

        if (response.meta && response.meta.current_page)
          this.setPageNumber(response.meta.current_page);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve Control unit types',
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  table = (options) => {
    this.list(options, KEYS.TABLE);
  };

  @action
  get_by_id = (id) => {
    this.setLoading(true);

    return app.store.api.control_unit_types
      .get_by_id(id)
      .then((response) => {
        this.set(KEYS.CONTROL_UNIT_TYPE, response, false);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve control unit type with id: '<strong>${id}</strong>'`,
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_software = (equipment_type_id) => {
    this.setBusy(true);

    return app.store.api.control_unit_types
      .get_software({ equipment_type_id, per_page: 999 })
      .then((response) => {
        this.set(KEYS.CONTROL_UNIT_TYPE_SOFTWARE, response, false);

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve software versions`,
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  store = (data) => {
    this.setBusy(true);

    return app.store.api.control_unit_types
      .store(data)
      .then(async (response) => {
        await this.set(KEYS.CONTROL_UNIT_TYPE, response, false);

        app.store.toasters.add({
          variant: 'success',
          title: `Control unit type <strong>${response.name}</strong> has been added.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to store control unit type`,
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  update = (id, data, options) => {
    this.setBusy(true);

    return app.store.api.control_unit_types
      .update(id, data, options)
      .then(async (response) => {
        await this.set(KEYS.CONTROL_UNIT_TYPE, response, false);

        app.store.toasters.add({
          variant: 'success',
          description: 'Control unit type details saved successfully.',
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to update control unit type (id: ${id})`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete = ({ id, name }) => {
    this.setBusy(true);

    return app.store.api.control_unit_types
      .delete(id)
      .then(async (response) => {
        app.store.toasters.add({
          variant: 'success',
          title: `Control unit type <strong>${name}</strong> deleted`,
        });

        await this.table();

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to delete control unit type <strong>${name}</strong>`,
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete_media = (data) => {
    this.setBusy(true);

    return app.store.api.control_unit_types
      .delete_media(data)
      .then(async (response) => {
        await this.set(KEYS.CONTROL_UNIT_TYPE, response);

        app.store.toasters.add({
          variant: 'success',
          title: `Control unit type image deleted`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to delete control unit type image`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  add_software_update = async (equipment_type_id, data, filename) => {
    this.setBusy(true);

    const toast = await app.store.toasters.add({
      variant: 'upload',
      title: 'Preparing upload',
      description: `Software Update is being uploaded`,
      indeterminate: true,
    });

    return app.store.api.control_unit_types
      .upload_software_update(data)
      .then(async (response) => {
        await this.get_software(equipment_type_id);

        app.store.toasters.update(toast.id, {
          title: 'Upload is complete',
          description: `Software Update <strong>${filename}</strong> has been uploaded`,
          variant: 'success',
          indeterminate: false,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.update(toast.id, {
            variant: 'error',
            title: `Failed to upload Software Update <strong>${filename}</strong>`,
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
            indeterminate: false,
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  update_software_update = async (equipment_type_id, id, data) => {
    this.setBusy(true);

    return app.store.api.control_unit_types
      .update_software_update(id, data)
      .then(async (response) => {
        await this.get_software(equipment_type_id);

        app.store.toasters.add({
          variant: 'success',
          description: `Software Update <strong>${data.name}</strong> details saved successfully.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            description: `Failed to update Software Update <strong>${data.name}</strong>.`,
            code: AcFormatErrorCode(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  download_software = async ({ id, name }) => {
    this.setBusy(true);

    const toast = await app.store.toasters.add({
      variant: 'download',
      title: 'Preparing download',
      description: `Software Update <strong>${name}</strong> has been requested`,
      indeterminate: true,
    });

    return app.store.api.control_unit_types
      .download_software(id)
      .then((response) => {
        app.store.toasters.update(toast.id, {
          title: 'Download started',
          description: `Software Update <strong>${name}</strong> has started`,
          variant: 'success',
          indeterminate: false,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        app.store.toasters.update(toast.id, {
          title: null,
          description: `Failed to download Software Update <strong>${name}</strong>`,
          code: AcFormatErrorCode(error),
          variant: 'error',
          indeterminate: false,
        });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete_software = ({ id, name }) => {
    this.setBusy(true);

    return app.store.api.control_unit_types
      .delete_software(id)
      .then((response) => {
        app.store.toasters.add({
          variant: 'success',
          description: `Software Update <strong>${name}</strong> has been deleted.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            description: `Failed to delete Software Update <strong>${name}</strong>.`,
            code: AcFormatErrorCode(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default ControlUnitTypesStore;
