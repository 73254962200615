// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcIsUndefined,
  AcFormatRequestParameters,
  AcFormatRawDataAsList,
} from '@utils';

const _default = {
  options: {},
  hydrohammer_types: null,
  hydrohammer_type: null,
};

let app = {};

export class HydrohammerTypesStore {
  constructor(store) {
    makeObservable(this);

    // AcAutoLoad(this, KEYS.HYDROHAMMER_TYPES);
    // AcAutoLoad(this, KEYS.HYDROHAMMER_TYPE);
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  hydrohammer_types = null;

  @observable
  hydrohammer_type = null;

  @computed
  get current_hydrohammer_types() {
    return this.hydrohammer_types;
  }

  @computed
  get current_hydrohammer_types_list() {
    if (!this.current_hydrohammer_types || !this.current_hydrohammer_types.data)
      return [];

    const result = new AcFormatRawDataAsList(
      this.current_hydrohammer_types.data,
      {
        ID: 'id',
        NAME: 'name',
        SERIES: 'series',
      }
    );

    return result;
  }

  @computed
  get current_hydrohammer_type() {
    return this.hydrohammer_type;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: KEYS.ASCENDING,
  };

  @observable
  page = 1;

  @observable
  query = null;

  @action
  setQuery = (input) => {
    const _input = AcSanitize(input);
    if (this.query !== _input) this.query = _input;
  };

  @action
  resetParams = () => {
    this.page = 1;
    this.query = null;
    this.per_page = 0;
    this.order_by = { field: null, direction: KEYS.ASCENDING };
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @action
  list = (options, endpoint = KEYS.LIST) => {
    this.setLoading(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.hydrohammer_types[endpoint](params)
      .then((response) => {
        this.set(KEYS.HYDROHAMMER_TYPES, response, false);

        if (response.meta && response.meta.current_page)
          this.setPageNumber(response.meta.current_page);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve Hydrohammer types',
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  table = (options) => {
    this.list(options, KEYS.TABLE);
  };

  @action
  get_by_id = (id) => {
    this.setLoading(true);

    return app.store.api.hydrohammer_types
      .get_by_id(id)
      .then((response) => {
        this.set(KEYS.HYDROHAMMER_TYPE, response, false);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve Hydrohammer type with id '<strong>${id}</strong>'`,
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  store = (data) => {
    this.setBusy(true);

    return app.store.api.hydrohammer_types
      .store(data)
      .then(async (response) => {
        await this.set(KEYS.HYDROHAMMER_TYPE, response, false);

        app.store.toasters.add({
          variant: 'success',
          title: `Hydrohammer type <strong>${response.name}</strong> has been added.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to store Hydrohammer type`,
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  update = (id, data, options) => {
    this.setBusy(true);

    return app.store.api.hydrohammer_types
      .update(id, data, options)
      .then(async (response) => {
        await this.set(KEYS.HYDROHAMMER_TYPE, response, false);

        app.store.toasters.add({
          variant: 'success',
          description: 'Hydrohammer type details saved successfully.',
          delay: 4 * 1000,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to update Hydrohammer type (id: ${id})`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete = ({ id, name }) => {
    this.setBusy(true);

    return app.store.api.hydrohammer_types
      .delete(id)
      .then(async (response) => {
        app.store.toasters.add({
          variant: 'success',
          title: `Hydrohammer type <strong>${name}</strong> deleted`,
        });

        await this.table();

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to delete Hydrohammer type <strong>${name}</strong>`,
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete_media = (data) => {
    this.setBusy(true);

    return app.store.api.hydrohammer_types
      .delete_media(data)
      .then(async (response) => {
        await this.set(KEYS.HYDROHAMMER_TYPE, response, false);

        app.store.toasters.add({
          variant: 'success',
          title: `Hydrohammer type image deleted`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to delete Hydrohammer type image`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default HydrohammerTypesStore;
